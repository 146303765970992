<template>
  <div class="slide-verify" :style="{width: w + 'px'}">
    <!-- 图片加载遮蔽罩 -->
    <div :class="{'slider-verify-loading': loadBlock}"></div>
    <canvas :width="w" :height="h" ref="canvas"></canvas>
    <canvas :width="blockW" :height="blockH" ref="block" class="slide-verify-block"></canvas>
    <img class="refresh-img" v-if="show" @click="refresh" src="../assets/fresh.png" alt=""/>
    <!-- container -->
    <div class="slide-verify-slider" ref="sliderDiv" :class="{'container-active': containerActive}">
      <div class="slide-verify-slider-mask" :style="{width: sliderMaskWidth}">
        <!-- slider -->
        <div @mousedown="sliderDown" @touchstart="touchStartEvent" @touchmove="touchMoveEvent" @touchend="touchEndEvent" class="slide-verify-slider-mask-item" :style="{left: sliderLeft}">
          <div class="slide-verify-slider-mask-item-icon"></div>
        </div>
      </div>
      <div class="slide-verify-slider-text">向右滑动 >></div>
    </div>
  </div>
</template>

<script>
import { getCaptcha } from "../common/api";

export default {
  name: "PhotoSlide",
  props: {
    // canvas width
    w: {
      type: Number,
      default: 280
    },
    // canvas height
    h: {
      type: Number,
      default: 150
    },
    blockW: {
      type: Number,
      default: 45
    },
    blockH: {
      type: Number,
      default: 150
    },
    show: {
      type: Boolean,
      default: true
    },
    phone: {
      type: String,
      default: ""
    }
  },
  data () {
    return {
      canvasCtx: null,
      blockCtx: null,
      block: null,

      containerActive: false,

      img: undefined,
      originX: undefined,
      originY: undefined,
      isMouseDown: false,
      trail: [],
      sliderLeft: 0,
      sliderMaskWidth: 0,
      loadBlock: true
    }
  },
  mounted () {
    this.init()
  },

  methods: {
    init() {
      this.initDom()
      this.initImg()
    },
    initDom() {
      this.block = this.$refs.block;
      this.canvasCtx = this.$refs.canvas.getContext('2d')
      this.blockCtx = this.block.getContext('2d')
    },
    async initImg() {
      this.loadBlock = true;
      let data = await this.getCaptchaImg(this.phone);
      if(data == null){
        this.$emit("initFailed");
      }else{
        this.drawImage(data.image1, data.image2);
      }
    },
    sliderDown(event) {
      this.originX = event.clientX;
      this.originY = event.clientY;
      this.isMouseDown = true;
    },
    touchStartEvent(e) {
      this.originX = e.changedTouches[0].pageX;
      this.originY = e.changedTouches[0].pageY;
      this.isMouseDown = true;
    },
    touchMoveEvent(e) {
      if (!this.isMouseDown) return false;
      const moveX = e.changedTouches[0].pageX - this.originX;
      const moveY = e.changedTouches[0].pageY - this.originY;
      if (moveX < 0 || moveX + 44 >= this.w) return false;
      this.sliderLeft = moveX + 'px';
      this.block.style.left = ((this.$refs.sliderDiv.clientWidth + 12) / this.w * moveX)  + 'px';

      this.containerActive = true;
      this.sliderMaskWidth = moveX + 'px';
      this.trail.push(moveY);
    },
    touchEndEvent(e) {
      if (!this.isMouseDown) return false
      this.isMouseDown = false
      if (e.changedTouches[0].pageX === this.originX) return false;
      this.containerActive = false;
      this.$emit("finish", parseInt(this.block.style.left))
    },
    async refresh() {
      if(this.loadBlock) return;
      this.loadBlock = true;
      this.containerActive = false;
      this.sliderLeft = 0;
      this.block.style.left = 0;
      this.sliderMaskWidth = 0;
      this.canvasCtx.clearRect(0, 0, this.w, this.h)
      this.blockCtx.clearRect(0, 0, this.blockW, this.blockH)

      let data = await this.getCaptchaImg(this.phone);
      if(data == null){
        this.$emit("initFailed");
      }else{
        this.drawImage(data.image1, data.image2);
      }
    },
    drawImage(imgData1, imgdata2) {
      const img2 = new Image()
      img2.src = imgdata2;
      img2.onload = () => {
        this.blockCtx.drawImage(img2, 0, 0, this.blockW, this.blockH);
      }

      const img1 = new Image()
      img1.crossOrigin = "Anonymous";
      img1.src = imgData1;
      img1.onload = () => {
        this.canvasCtx.drawImage(img1, 0, 0, this.w, this.h);
        this.loadBlock = false;
      };
    },
    async getCaptchaImg(phone) {
      return await getCaptcha({phone: phone}).then(data => {
        return data;
      }).catch(error => {
        return null;
      });
    }
  }
}
</script>

<style scoped>
.slide-verify {
  position: relative;
}

.slider-verify-loading {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 999;
  animation: loading 1.5s infinite;
}

@keyframes loading {
  0% { opacity: 0.7; }
  100% { opacity: 9; }
}

.slide-verify-block {
  position: absolute;
  left: 0;
  top: 0;
}

.refresh-img {
  width: 34px;
  height: 34px;
  position: absolute;
  top: 0;
  right: 0;
}

.slide-verify-slider {
  position: relative;
  text-align: center;
  width: calc(100% - 2px);
  height: 40px;
  line-height: 40px;
  background-color: #f7f9fa;
  color: #45494c;
  border: 1px solid #e4e7eb;
}

.slide-verify-slider-mask {
  position: absolute;
  left: 0;
  top: 0;
  height: 40px;
  border: 0 solid #1991fa;
  background-color: #d1e9fe;
}

.slide-verify-slider-mask-item {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  background-color: #fff;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  transition: background 0.2s linear;
}

.slide-verify-slider-mask-item:hover {
  background-color: #1991fa;
}

.slide-verify-slider-mask-item:hover .slide-verify-slider-mask-item-icon {
  opacity: 0;
}

.slide-verify-slider-mask-item-icon {
  width: 32px;
  height: 32px;
  margin-top: 4px;
  margin-left: 4px;
  background: url("../assets/move-right.png") no-repeat fixed center;
}
.container-active .slide-verify-slider-mask-item {
  height: 38px;
  top: -1px;
  border: 1px solid #1991fa;
}

.container-active .slide-verify-slider-mask {
  height: 38px;
  border-width: 1px;
}

.slide-verify-slider-text {
  font-size: 14px;
}

.container-active .slide-verify-slider-text {
  display: none;
}
</style>