<template>
  <div class="page-div">
    <div class="logo-div">
      <img src="../assets/logo.png" alt="">
    </div>
    <div class="title-div">
      <label>邀请您一起玩转蛙机通赚现金</label>
    </div>
    <div class="box-div">
      <div class="subtitle-div">
        <label>请填写您的信息</label>
      </div>
      <div class="item-div">
        <label><input type="number" v-model="acc" maxlength="18" placeholder="请输入手机号码"></label>
      </div>
      <div class="item-div">
        <label><input type="password" v-model="pwd" maxlength="18" placeholder="请输入密码"></label>
      </div>
      <div class="code-div">
        <label><input class="code-input" v-model="code" type="text" placeholder="请输入验证码"></label>
        <input class="code-btn" type="button" :value="codeBtnValue" @click="onGetCaptcha">
      </div>
    </div>
    <div class="btn-div">
      <input type="button" value="立即领取" @click="doRegister">
    </div>
    <div class="download-div" v-show="isShowDownloadBox">
      <div class="download-box-div">
        <div class="download-close-div">
          <img src="../assets/close.png" alt="" @click="doCloseDownloadBox"/>
        </div>
        <div>
          <img src="../assets/success.png" height="64" width="64" alt=""/>
        </div>
        <div class="download-title-div">
          <label>新用户福利已存入账号</label>
        </div>
        <div class="download-subtitle-div">
          <label>请使用{{accMsg}}登录蛙机通即得</label>
        </div>
        <div class="download-btn-div">
          <input type="button" value="立即下载" @click="doDownloadJump">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CaptchaLayer from "./layer/CaptchaLayer";
import JSEncrypt from "jsencrypt"
import { getPublicKey, postRegister } from "../common/api";

export default {
  name: "Register",
  data() {
    return {
      promoter: "",
      downloadPageUrl: "https://a.app.qq.com/o/simple.jsp?pkgname=com.shanjiang.excavatorservice",
      acc: "",
      pwd: "",
      code: "",
      cdTime: 0,
      timer: null,
      encrypt: null,
      isLoading: false,
      isShowDownloadBox: false,
      accMsg: ""
    }
  },
  created() {
    let query = this.$route.query;
    if(Object.prototype.hasOwnProperty.call(query, "promoter") && /^\d+$/.test(query.promoter)){
      this.promoter = query.promoter;
    }
  },
  computed: {
    codeBtnValue() {
      return this.cdTime > 0 ? `获取验证码(${this.cdTime}秒)` : "获取验证码";
    }
  },
  methods: {
    onGetCaptcha() {
      if(this.cdTime > 0){
        return;
      }

      if(this.acc.length < 1){
        this.$layer.msg("请输入您的手机号码");
        return;
      }

      if(/^1\d{10}$/.test(this.acc)){
        this.onShowCaptchaLayer(this.acc)
      }else{
        this.$layer.msg("手机号码格式错误");
      }
    },
    onShowCaptchaLayer(phone) {
      this.$layer.iframe({
        content: {
          content: CaptchaLayer,
          parent: this,
          data: {
            phone: phone,
            callback: this.codeCD
          }
        }
      });
    },
    codeCD(data){
      if(Object.prototype.hasOwnProperty.call(data, "code")){
        this.code = data.code
      }

      if(!this.timer){
        this.cdTime = data.cdTime;
        this.timer = setInterval(() => {
          if(this.cdTime > 0){
            --this.cdTime;
          }else{
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      }
    },
    async doRegister() {
      if(this.isLoading){
        return;
      }

      if(!/^1\d{10}$/.test(this.acc)){
        this.$layer.msg("手机号码格式错误");
        return;
      }

      if(this.pwd.length < 6 || this.pwd.length > 18){
        this.$layer.msg("请输入6~18位密码");
        return;
      }

      if(this.code.length < 1){
        this.$layer.msg("请输入短信验证码");
        return;
      }

      if(this.encrypt == null){
        let publicKey = await this.doGetPublicKey();
        if(publicKey == null){
          this.$layer.msg("注册失败，请稍后重试");
          return;
        }

        let encrypt = new JSEncrypt();
        encrypt.setPublicKey(publicKey);
        this.encrypt = encrypt;
      }

      let pwd = this.encrypt.encrypt(this.pwd);
      this.isLoading = true;
      postRegister({
        acc: this.acc,
        pwd: pwd,
        code: this.code,
        recommenderId: this.promoter
      }).then(data => {
        this.$layer.msg("注册成功");
        this.isLoading = false;
        this.isShowDownloadBox = true;

        this.accMsg = `${this.acc.substr(0, 3)}****${this.acc.slice(7)}`;

        this.acc = "";
        this.pwd = "";
        this.code = "";
        if(this.cdTime > 0){
          this.cdTime = 0;
        }
      }).catch(error => {
        this.isLoading = false;
        if(Object.prototype.hasOwnProperty.call(error, "data") && Object.prototype.hasOwnProperty.call(error.data, "status")){
          this.$layer.msg(error.data.msg);
        }else{
          this.$layer.msg("注册失败，请稍后重试");
        }
      });
    },
    async doGetPublicKey() {
      return await getPublicKey()
        .then(data => {
          return data;
        })
        .catch(() => {
          return null;
        });
    },
    doDownloadJump() {
      window.open(this.downloadPageUrl, "_blank");
      this.isShowDownloadBox = false;
    },
    doCloseDownloadBox() {
      this.isShowDownloadBox = false;
    }
  }
}
</script>

<style lang="less" scoped>
.page-div {
  width: 100%;
  height: 100%;
  background-image: linear-gradient(#01BBB5, #F9ECA4);
  .logo-div {
    padding: 0.5rem 0 0.1rem 0;
    text-align: center;
  }
  .title-div {
    font-size: 0.18rem;
    color: #FFFFFF;
    text-align: center;
  }
  .box-div {
    margin-top: 0.20rem;
    text-align: center;
    .subtitle-div {
      margin-bottom: 0.1rem;
      font-size: 0.15rem;
      color: #FFFFFF;
    }
    .item-div {
      padding: 0.1rem 0;
      input {
        width: 2.2rem;
        height: 0.36rem;
        padding-left: 0.1rem;
        padding-right: 0.1rem;
        background-color: #FFFFFF;
        border: none;
        border-radius: 0.05rem;
      }
    }
    .code-div {
      padding: 0.10rem 0;
      justify-content: space-between;
      .code-input {
        width: 0.95rem;
        height: 0.36rem;
        margin-right: 0.05rem;
        padding-left: 0.1rem;
        padding-right: 0.1rem;
        background-color: #FFFFFF;
        border: none;
        border-radius: 0.05rem;
      }
      .code-btn {
        width: 1.2rem;
        height: 0.36rem;
        background-color: #FFFFFF;
        border: none;
        border-radius: 0.18rem;
        color: #03AD53;
      }
    }
  }
  .btn-div {
    width: 100%;
    text-align: center;
    margin-top: 0.6rem;
    input {
      width: 2.2rem;
      height: 0.46rem;
      font-size: 0.18rem;
      color: #FFFFFF;
      background-color: #01BBB5;
      border: none;
      border-radius: 0.23rem;
    }
  }
  .download-div {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.6);
    .download-box-div {
      position: fixed;
      top: 35%;
      left: 10%;
      width: 80%;
      padding: 0.1rem 0 0.15rem 0;
      background-color: #FEF5CA;
      text-align: center;
      border-radius: 0.05rem;
      .download-close-div {
        position: absolute;
        top: 0.02rem;
        right: 0.02rem;
        img {
          width: 0.32rem;
          height: 0.32rem;
        }
      }
      .download-title-div {
        margin-top: 0.1rem;
      }
      .download-subtitle-div {
        margin-top: 0.05rem;
        margin-bottom: 0.25rem;
      }
      .download-btn-div {
        input {
          width: 1.8rem;
          height: 0.36rem;
          font-size: 0.16rem;
          color: #FFFFFF;
          background-color: #00938D;
          border: none;
          border-radius: 0.18rem;
        }
      }
    }
  }
}
</style>