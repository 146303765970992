<template>
  <div class="layer-page-div">
    <photo-slide :phone="phone" @finish="onFinish" @initFailed="onInitFailed"></photo-slide>
  </div>
</template>

<script>
import PhotoSlide from "../../components/PhotoSlide"
import { postRegisterCode } from "../../common/api";

export default {
  components: { PhotoSlide },
  props: {
    layerid: {
      type: String,
      default: ""
    },
    phone: {
      type: String,
      default: ""
    },
    callback: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      msg: '',
      loginBtn: false,
      accuracy: 1,
      formLabelAlign: {},
      tipMsg: ""
    }
  },
  methods: {
    onFinish(x) {
      this.$layer.close(this.layerid);
      postRegisterCode({
        phone: this.phone,
        width: 0,
        x: x}
      ).then(data => {
        this.callback(data);
      }).catch(error => {
        if(Object.prototype.hasOwnProperty.call(error, "data") && Object.prototype.hasOwnProperty.call(error.data, "status")){
          this.$layer.msg(error.data.msg);
        }else{
          this.$layer.msg("获取短信失败，请稍后重试");
        }
      });
    },
    onInitFailed() {
      this.$layer.msg("获取验证图形失败，请稍后重试");
      this.$layer.close(this.layerid);
    },
    setTipsMsg(msg) {
      this.tipMsg = msg;
      clearTimeout(this.timeCounter);
      this.timeCounter = setTimeout(() => {
        this.tipMsg = "";
      }, 3000);
    }
  }
}
</script>

<style lang="less" scoped>
.layer-page-div {
  overflow: hidden;
}
</style>